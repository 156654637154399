import _definePage_default_0 from '/opt/buildhome/repo/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/opt/buildhome/repo/src/pages/[...all].vue?definePage&vue'
import _definePage_default_3 from '/opt/buildhome/repo/src/pages/admin/analyses/index.vue?definePage&vue'
import _definePage_default_4 from '/opt/buildhome/repo/src/pages/admin/analyses/[uuid].vue?definePage&vue'
import _definePage_default_5 from '/opt/buildhome/repo/src/pages/admin/api-keys/index.vue?definePage&vue'
import _definePage_default_6 from '/opt/buildhome/repo/src/pages/admin/contacts/index.vue?definePage&vue'
import _definePage_default_7 from '/opt/buildhome/repo/src/pages/admin/debug/index.vue?definePage&vue'
import _definePage_default_8 from '/opt/buildhome/repo/src/pages/admin/devices/index.vue?definePage&vue'
import _definePage_default_9 from '/opt/buildhome/repo/src/pages/admin/groups/index.vue?definePage&vue'
import _definePage_default_10 from '/opt/buildhome/repo/src/pages/admin/logger/index.vue?definePage&vue'
import _definePage_default_11 from '/opt/buildhome/repo/src/pages/admin/maps/index.vue?definePage&vue'
import _definePage_default_12 from '/opt/buildhome/repo/src/pages/admin/maps/[uuid].vue?definePage&vue'
import _definePage_default_13 from '/opt/buildhome/repo/src/pages/admin/notebooks/index.vue?definePage&vue'
import _definePage_default_14 from '/opt/buildhome/repo/src/pages/admin/observations/index.vue?definePage&vue'
import _definePage_default_15 from '/opt/buildhome/repo/src/pages/admin/satellite/index.vue?definePage&vue'
import _definePage_default_16 from '/opt/buildhome/repo/src/pages/admin/satellite/new.vue?definePage&vue'
import _definePage_default_17 from '/opt/buildhome/repo/src/pages/admin/users/index.vue?definePage&vue'
import _definePage_default_18 from '/opt/buildhome/repo/src/pages/admin/users/[uuid].vue?definePage&vue'
import _definePage_default_19 from '/opt/buildhome/repo/src/pages/admin/webcams/index.vue?definePage&vue'
import _definePage_default_20 from '/opt/buildhome/repo/src/pages/analyses/index.vue?definePage&vue'
import _definePage_default_21 from '/opt/buildhome/repo/src/pages/analyses/[uuid].vue?definePage&vue'
import _definePage_default_22 from '/opt/buildhome/repo/src/pages/analyses/new.vue?definePage&vue'
import _definePage_default_23 from '/opt/buildhome/repo/src/pages/analyses-provider/index.vue?definePage&vue'
import _definePage_default_24 from '/opt/buildhome/repo/src/pages/analyses-provider/[uuid].vue?definePage&vue'
import _definePage_default_25 from '/opt/buildhome/repo/src/pages/analyses-provider/client/[uuid].vue?definePage&vue'
import _definePage_default_26 from '/opt/buildhome/repo/src/pages/assistant.vue?definePage&vue'
import _definePage_default_27 from '/opt/buildhome/repo/src/pages/blog/[slug].vue?definePage&vue'
import _definePage_default_28 from '/opt/buildhome/repo/src/pages/dashboard.vue?definePage&vue'
import _definePage_default_29 from '/opt/buildhome/repo/src/pages/devices/[uuid]/index.vue?definePage&vue'
import _definePage_default_30 from '/opt/buildhome/repo/src/pages/devices/[uuid]/[channel].vue?definePage&vue'
import _definePage_default_31 from '/opt/buildhome/repo/src/pages/devices/[uuid]/all.vue?definePage&vue'
import _definePage_default_32 from '/opt/buildhome/repo/src/pages/devices/mine.vue?definePage&vue'
import _definePage_default_33 from '/opt/buildhome/repo/src/pages/devices/public.vue?definePage&vue'
import _definePage_default_34 from '/opt/buildhome/repo/src/pages/forgot-password.vue?definePage&vue'
import _definePage_default_35 from '/opt/buildhome/repo/src/pages/groups/index.vue?definePage&vue'
import _definePage_default_36 from '/opt/buildhome/repo/src/pages/groups/[uuid].vue?definePage&vue'
import _definePage_default_37 from '/opt/buildhome/repo/src/pages/lnd/analyses.vue?definePage&vue'
import _definePage_default_38 from '/opt/buildhome/repo/src/pages/login.vue?definePage&vue'
import _definePage_default_39 from '/opt/buildhome/repo/src/pages/maps/index.vue?definePage&vue'
import _definePage_default_40 from '/opt/buildhome/repo/src/pages/maps/[uuid].vue?definePage&vue'
import _definePage_default_41 from '/opt/buildhome/repo/src/pages/marketing/blog/index.vue?definePage&vue'
import _definePage_default_42 from '/opt/buildhome/repo/src/pages/marketing/blog/[slug].vue?definePage&vue'
import _definePage_default_43 from '/opt/buildhome/repo/src/pages/marketing/maps/index.vue?definePage&vue'
import _definePage_default_44 from '/opt/buildhome/repo/src/pages/notebooks/index.vue?definePage&vue'
import _definePage_default_45 from '/opt/buildhome/repo/src/pages/notebooks/[uuid].vue?definePage&vue'
import _definePage_default_46 from '/opt/buildhome/repo/src/pages/observations/[uuid].vue?definePage&vue'
import _definePage_default_47 from '/opt/buildhome/repo/src/pages/policy.vue?definePage&vue'
import _definePage_default_48 from '/opt/buildhome/repo/src/pages/public-groups/[uuid].vue?definePage&vue'
import _definePage_default_49 from '/opt/buildhome/repo/src/pages/register.vue?definePage&vue'
import _definePage_default_50 from '/opt/buildhome/repo/src/pages/reset-password.vue?definePage&vue'
import _definePage_default_51 from '/opt/buildhome/repo/src/pages/satellite/index.vue?definePage&vue'
import _definePage_default_52 from '/opt/buildhome/repo/src/pages/satellite/[uuid].vue?definePage&vue'
import _definePage_default_53 from '/opt/buildhome/repo/src/pages/settings/[tab].vue?definePage&vue'
import _definePage_default_54 from '/opt/buildhome/repo/src/pages/stations/index.vue?definePage&vue'
import _definePage_default_55 from '/opt/buildhome/repo/src/pages/stations/[uuid]/index.vue?definePage&vue'
import _definePage_default_56 from '/opt/buildhome/repo/src/pages/stations/[uuid]/[channel].vue?definePage&vue'
import _definePage_default_57 from '/opt/buildhome/repo/src/pages/stations/[uuid]/all.vue?definePage&vue'
import _definePage_default_58 from '/opt/buildhome/repo/src/pages/verify-email.vue?definePage&vue'
import _definePage_default_59 from '/opt/buildhome/repo/src/pages/webcams/index.vue?definePage&vue'
import _definePage_default_60 from '/opt/buildhome/repo/src/pages/webcams/[id].vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'root',
    component: () => import('/opt/buildhome/repo/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:all(.*)',
    name: '$all',
    component: () => import('/opt/buildhome/repo/src/pages/[...all].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  {
    path: '/admin',
    /* internal name: 'admin' */
    /* no component */
    children: [
      {
        path: 'analyses',
        /* internal name: 'admin-analyses' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-analyses',
            component: () => import('/opt/buildhome/repo/src/pages/admin/analyses/index.vue'),
            /* no children */
          },
  _definePage_default_3
  ),
  _mergeRouteRecord(
          {
            path: ':uuid',
            name: 'admin-analyses-uuid',
            component: () => import('/opt/buildhome/repo/src/pages/admin/analyses/[uuid].vue'),
            /* no children */
          },
  _definePage_default_4
  )
        ],
      },
      {
        path: 'api-keys',
        /* internal name: 'admin-api-keys' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-api-keys',
            component: () => import('/opt/buildhome/repo/src/pages/admin/api-keys/index.vue'),
            /* no children */
          },
  _definePage_default_5
  )
        ],
      },
      {
        path: 'contacts',
        /* internal name: 'admin-contacts' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-contacts',
            component: () => import('/opt/buildhome/repo/src/pages/admin/contacts/index.vue'),
            /* no children */
          },
  _definePage_default_6
  )
        ],
      },
      {
        path: 'debug',
        /* internal name: 'admin-debug' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-debug',
            component: () => import('/opt/buildhome/repo/src/pages/admin/debug/index.vue'),
            /* no children */
          },
  _definePage_default_7
  )
        ],
      },
      {
        path: 'devices',
        /* internal name: 'admin-devices' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-devices',
            component: () => import('/opt/buildhome/repo/src/pages/admin/devices/index.vue'),
            /* no children */
          },
  _definePage_default_8
  )
        ],
      },
      {
        path: 'groups',
        /* internal name: 'admin-groups' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-groups',
            component: () => import('/opt/buildhome/repo/src/pages/admin/groups/index.vue'),
            /* no children */
          },
  _definePage_default_9
  )
        ],
      },
      {
        path: 'logger',
        /* internal name: 'admin-logger' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-logger',
            component: () => import('/opt/buildhome/repo/src/pages/admin/logger/index.vue'),
            /* no children */
          },
  _definePage_default_10
  )
        ],
      },
      {
        path: 'maps',
        /* internal name: 'admin-maps' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-maps',
            component: () => import('/opt/buildhome/repo/src/pages/admin/maps/index.vue'),
            /* no children */
          },
  _definePage_default_11
  ),
  _mergeRouteRecord(
          {
            path: ':uuid',
            name: 'admin-maps-uuid',
            component: () => import('/opt/buildhome/repo/src/pages/admin/maps/[uuid].vue'),
            /* no children */
          },
  _definePage_default_12
  )
        ],
      },
      {
        path: 'notebooks',
        /* internal name: 'admin-notebooks' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-notebooks',
            component: () => import('/opt/buildhome/repo/src/pages/admin/notebooks/index.vue'),
            /* no children */
          },
  _definePage_default_13
  )
        ],
      },
      {
        path: 'observations',
        /* internal name: 'admin-observations' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-observations',
            component: () => import('/opt/buildhome/repo/src/pages/admin/observations/index.vue'),
            /* no children */
          },
  _definePage_default_14
  )
        ],
      },
      {
        path: 'satellite',
        /* internal name: 'admin-satellite' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-satellite',
            component: () => import('/opt/buildhome/repo/src/pages/admin/satellite/index.vue'),
            /* no children */
          },
  _definePage_default_15
  ),
  _mergeRouteRecord(
          {
            path: 'new',
            name: 'admin-satellite-new',
            component: () => import('/opt/buildhome/repo/src/pages/admin/satellite/new.vue'),
            /* no children */
          },
  _definePage_default_16
  )
        ],
      },
      {
        path: 'users',
        /* internal name: 'admin-users' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-users',
            component: () => import('/opt/buildhome/repo/src/pages/admin/users/index.vue'),
            /* no children */
          },
  _definePage_default_17
  ),
  _mergeRouteRecord(
          {
            path: ':uuid',
            name: 'admin-users-uuid',
            component: () => import('/opt/buildhome/repo/src/pages/admin/users/[uuid].vue'),
            /* no children */
          },
  _definePage_default_18
  )
        ],
      },
      {
        path: 'webcams',
        /* internal name: 'admin-webcams' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-webcams',
            component: () => import('/opt/buildhome/repo/src/pages/admin/webcams/index.vue'),
            /* no children */
          },
  _definePage_default_19
  )
        ],
      }
    ],
  },
  {
    path: '/analyses',
    /* internal name: 'analyses' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'analyses',
        component: () => import('/opt/buildhome/repo/src/pages/analyses/index.vue'),
        /* no children */
      },
  _definePage_default_20
  ),
  _mergeRouteRecord(
      {
        path: ':uuid',
        name: 'analyses-uuid',
        component: () => import('/opt/buildhome/repo/src/pages/analyses/[uuid].vue'),
        /* no children */
      },
  _definePage_default_21
  ),
  _mergeRouteRecord(
      {
        path: 'new',
        name: 'analyses-new',
        component: () => import('/opt/buildhome/repo/src/pages/analyses/new.vue'),
        /* no children */
      },
  _definePage_default_22
  )
    ],
  },
  {
    path: '/analyses-provider',
    /* internal name: 'analyses-provider' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'analyses-provider',
        component: () => import('/opt/buildhome/repo/src/pages/analyses-provider/index.vue'),
        /* no children */
      },
  _definePage_default_23
  ),
  _mergeRouteRecord(
      {
        path: ':uuid',
        name: 'analyses-provider-uuid',
        component: () => import('/opt/buildhome/repo/src/pages/analyses-provider/[uuid].vue'),
        /* no children */
      },
  _definePage_default_24
  ),
      {
        path: 'client',
        /* internal name: 'analyses-provider-client' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':uuid',
            name: 'analyses-provider-client-uuid',
            component: () => import('/opt/buildhome/repo/src/pages/analyses-provider/client/[uuid].vue'),
            /* no children */
          },
  _definePage_default_25
  )
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/assistant',
    name: 'assistant',
    component: () => import('/opt/buildhome/repo/src/pages/assistant.vue'),
    /* no children */
  },
  _definePage_default_26
  ),
  {
    path: '/blog',
    /* internal name: 'blog' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':slug',
        name: 'blog-slug',
        component: () => import('/opt/buildhome/repo/src/pages/blog/[slug].vue'),
        /* no children */
      },
  _definePage_default_27
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('/opt/buildhome/repo/src/pages/dashboard.vue'),
    /* no children */
  },
  _definePage_default_28
  ),
  {
    path: '/devices',
    /* internal name: 'devices' */
    /* no component */
    children: [
      {
        path: ':uuid',
        /* internal name: 'devices-uuid' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'devices-uuid',
            component: () => import('/opt/buildhome/repo/src/pages/devices/[uuid]/index.vue'),
            /* no children */
          },
  _definePage_default_29
  ),
  _mergeRouteRecord(
          {
            path: ':channel',
            name: 'devices-uuid-channel',
            component: () => import('/opt/buildhome/repo/src/pages/devices/[uuid]/[channel].vue'),
            /* no children */
          },
  _definePage_default_30
  ),
  _mergeRouteRecord(
          {
            path: 'all',
            name: 'devices-uuid-all',
            component: () => import('/opt/buildhome/repo/src/pages/devices/[uuid]/all.vue'),
            /* no children */
          },
  _definePage_default_31
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'mine',
        name: 'devices-mine',
        component: () => import('/opt/buildhome/repo/src/pages/devices/mine.vue'),
        /* no children */
      },
  _definePage_default_32
  ),
  _mergeRouteRecord(
      {
        path: 'public',
        name: 'devices-public',
        component: () => import('/opt/buildhome/repo/src/pages/devices/public.vue'),
        /* no children */
      },
  _definePage_default_33
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('/opt/buildhome/repo/src/pages/forgot-password.vue'),
    /* no children */
  },
  _definePage_default_34
  ),
  {
    path: '/groups',
    /* internal name: 'groups' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'groups',
        component: () => import('/opt/buildhome/repo/src/pages/groups/index.vue'),
        /* no children */
      },
  _definePage_default_35
  ),
  _mergeRouteRecord(
      {
        path: ':uuid',
        name: 'groups-uuid',
        component: () => import('/opt/buildhome/repo/src/pages/groups/[uuid].vue'),
        /* no children */
      },
  _definePage_default_36
  )
    ],
  },
  {
    path: '/lnd',
    /* internal name: 'lnd' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'analyses',
        name: 'lnd-analyses',
        component: () => import('/opt/buildhome/repo/src/pages/lnd/analyses.vue'),
        /* no children */
      },
  _definePage_default_37
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('/opt/buildhome/repo/src/pages/login.vue'),
    /* no children */
  },
  _definePage_default_38
  ),
  {
    path: '/maps',
    /* internal name: 'maps' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'maps',
        component: () => import('/opt/buildhome/repo/src/pages/maps/index.vue'),
        /* no children */
      },
  _definePage_default_39
  ),
  _mergeRouteRecord(
      {
        path: ':uuid',
        name: 'maps-uuid',
        component: () => import('/opt/buildhome/repo/src/pages/maps/[uuid].vue'),
        /* no children */
      },
  _definePage_default_40
  )
    ],
  },
  {
    path: '/marketing',
    /* internal name: 'marketing' */
    /* no component */
    children: [
      {
        path: 'blog',
        /* internal name: 'marketing-blog' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'marketing-blog',
            component: () => import('/opt/buildhome/repo/src/pages/marketing/blog/index.vue'),
            /* no children */
          },
  _definePage_default_41
  ),
  _mergeRouteRecord(
          {
            path: ':slug',
            name: 'marketing-blog-slug',
            component: () => import('/opt/buildhome/repo/src/pages/marketing/blog/[slug].vue'),
            /* no children */
          },
  _definePage_default_42
  )
        ],
      },
      {
        path: 'maps',
        /* internal name: 'marketing-maps' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'marketing-maps',
            component: () => import('/opt/buildhome/repo/src/pages/marketing/maps/index.vue'),
            /* no children */
          },
  _definePage_default_43
  )
        ],
      }
    ],
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('/opt/buildhome/repo/src/pages/not-authorized.vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "role": "user"
    }
  },
  {
    path: '/notebooks',
    /* internal name: 'notebooks' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'notebooks',
        component: () => import('/opt/buildhome/repo/src/pages/notebooks/index.vue'),
        /* no children */
      },
  _definePage_default_44
  ),
  _mergeRouteRecord(
      {
        path: ':uuid',
        name: 'notebooks-uuid',
        component: () => import('/opt/buildhome/repo/src/pages/notebooks/[uuid].vue'),
        /* no children */
      },
  _definePage_default_45
  )
    ],
  },
  {
    path: '/observations',
    /* internal name: 'observations' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':uuid',
        name: 'observations-uuid',
        component: () => import('/opt/buildhome/repo/src/pages/observations/[uuid].vue'),
        /* no children */
      },
  _definePage_default_46
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/policy',
    name: 'policy',
    component: () => import('/opt/buildhome/repo/src/pages/policy.vue'),
    /* no children */
  },
  _definePage_default_47
  ),
  {
    path: '/public-groups',
    /* internal name: 'public-groups' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':uuid',
        name: 'public-groups-uuid',
        component: () => import('/opt/buildhome/repo/src/pages/public-groups/[uuid].vue'),
        /* no children */
      },
  _definePage_default_48
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/register',
    name: 'register',
    component: () => import('/opt/buildhome/repo/src/pages/register.vue'),
    /* no children */
  },
  _definePage_default_49
  ),
  _mergeRouteRecord(
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('/opt/buildhome/repo/src/pages/reset-password.vue'),
    /* no children */
  },
  _definePage_default_50
  ),
  {
    path: '/satellite',
    /* internal name: 'satellite' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'satellite',
        component: () => import('/opt/buildhome/repo/src/pages/satellite/index.vue'),
        /* no children */
      },
  _definePage_default_51
  ),
  _mergeRouteRecord(
      {
        path: ':uuid',
        name: 'satellite-uuid',
        component: () => import('/opt/buildhome/repo/src/pages/satellite/[uuid].vue'),
        /* no children */
      },
  _definePage_default_52
  )
    ],
  },
  {
    path: '/settings',
    /* internal name: 'settings' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':tab',
        name: 'settings-tab',
        component: () => import('/opt/buildhome/repo/src/pages/settings/[tab].vue'),
        /* no children */
      },
  _definePage_default_53
  )
    ],
  },
  {
    path: '/stations',
    /* internal name: 'stations' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'stations',
        component: () => import('/opt/buildhome/repo/src/pages/stations/index.vue'),
        /* no children */
      },
  _definePage_default_54
  ),
      {
        path: ':uuid',
        /* internal name: 'stations-uuid' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'stations-uuid',
            component: () => import('/opt/buildhome/repo/src/pages/stations/[uuid]/index.vue'),
            /* no children */
          },
  _definePage_default_55
  ),
  _mergeRouteRecord(
          {
            path: ':channel',
            name: 'stations-uuid-channel',
            component: () => import('/opt/buildhome/repo/src/pages/stations/[uuid]/[channel].vue'),
            /* no children */
          },
  _definePage_default_56
  ),
  _mergeRouteRecord(
          {
            path: 'all',
            name: 'stations-uuid-all',
            component: () => import('/opt/buildhome/repo/src/pages/stations/[uuid]/all.vue'),
            /* no children */
          },
  _definePage_default_57
  )
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('/opt/buildhome/repo/src/pages/verify-email.vue'),
    /* no children */
  },
  _definePage_default_58
  ),
  {
    path: '/webcams',
    /* internal name: 'webcams' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'webcams',
        component: () => import('/opt/buildhome/repo/src/pages/webcams/index.vue'),
        /* no children */
      },
  _definePage_default_59
  ),
  _mergeRouteRecord(
      {
        path: ':id',
        name: 'webcams-id',
        component: () => import('/opt/buildhome/repo/src/pages/webcams/[id].vue'),
        /* no children */
      },
  _definePage_default_60
  )
    ],
  }
]
